import React, { useState } from "react";
import axios from "axios";
import { graphql } from "gatsby";
import Layout from "../layouts/Layout";
import SiteMetadata from "../components/SiteMetadata";
import HeadingTop from "../components/BuildingBlocks/HeadingTop";
import LogInButton from "../components/Authentication/LogInButton";
import LogoutButton from "../components/Authentication/LogOutButton";
import UserProfile from "../components/Authentication/UserProfile";

const ContactPage = ({ data }) => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/cd90fe2b-c797-46cb-809f-ad8b06949737",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Danke!", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <Layout>
      <SiteMetadata
        title="Kontakt"
        description="Du hast Fragen, Anmerkungen oder möchtest etwas loswerden? Dann kontaktiere uns!"
      />
      <HeadingTop
        title={"Kontakt"}
        subtitle={"Wir freuen uns, von Dir zu hören!"}
      />
      <div className="container-xl mb-8">
        <div className="max-w-screen-md mx-auto pt-10 text-lg">
          <p className="py-4">
            Du hast Fragen, Anmerkungen oder möchtest etwas loswerden? Dann
            kontaktiere uns bitte über folgendes Formular. Wir werden uns so
            schnell wie möglich melden!
          </p>

          <form className="py-4" onSubmit={handleOnSubmit} autocomplete="off">
            <div class="form-group py-4">
              <label
                for="exampleInputEmail1"
                htmlFor="exampleInputEmail1"
                required="required"
              >
                Email-Adresse
              </label>
              <br />
              <input
                type="email"
                name="email"
                class="form-control form-input w-full"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Deine Email-Adresse"
              />
            </div>
            <div class="form-group py-4">
              <label for="exampleInputName" htmlFor="exampleInputName">
                Name
              </label>
              <br />
              <input
                type="text"
                name="name"
                class="form-control form-input w-full"
                id="exampleInputName"
                placeholder="Dein Name"
                required="required"
              />
            </div>
            <div class="form-group py-4">
              <label for="message" htmlFor="message">
                Nachricht
              </label>
              <br />
              <textarea
                name="message"
                class="form-control form-textarea w-full"
                rows="3"
                id="message"
                placeholder="Deine Nachricht"
                required="required"
              />
            </div>
            <button
              type="submit"
              className="bg-1 text-white font-bold px-4 py-3.5 rounded-2xl hover:bg-red-500 transition duration-300"
              disabled={serverState.submitting}
            >
              Abschicken
            </button>
            {serverState.status && (
              <p className={!serverState.status.ok ? "errorMsg" : ""}>
                {serverState.status.msg}
              </p>
            )}
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query ContactQuery {
    persons: allContentfulPerson {
      nodes {
        childContentfulPersonTitleTextNode {
          title
        }
        icon {
          file {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    events: allContentfulAnlass(sort: { order: ASC, fields: date }) {
      nodes {
        date(locale: "de", formatString: "DD. MMMM")
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        title
      }
    }
    nextEvent: allContentfulAnlass(sort: { order: ASC, fields: date }) {
      nodes {
        title
        date(fromNow: true, locale: "de")
        image {
          file {
            url
          }
        }
      }
    }
  }
`;
